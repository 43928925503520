<template>
  <Portal>
    <div v-if="isOpen" :class="styles.root">
      <div :class="[styles.background, styles.animation]" role="presentation" @click="closeHandler" />
      <div :class="[styles.holder, styles[size], styles.animation]">
        <button :class="styles.close" type="button" @click="onClose()">
          <IconClose width="24" height="24" />
        </button>
        <div v-if="preloader" :class="[styles.preloader, styles[size]]" />
        <div :class="styles.content">
          <slot />
        </div>
      </div>
    </div>
  </Portal>
</template>

<script lang="ts" setup>
import styles from './Modal.module.scss';
import { onMounted, onUnmounted, watch } from 'vue';
import type { ModalEmits } from './emits';
import { Portal } from '~/shared/ui/Portal';
import IconClose from '~/shared/assets/icons/icon-close.svg';

interface ModalProps {
  isOpen: boolean;
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  preloader?: boolean;
  onClose: () => void;
}

const emits = defineEmits<ModalEmits>();

const props = withDefaults(defineProps<ModalProps>(), {
  size: 'medium',
  preloader: false,
});

const closeHandler = () => {
  props.onClose();
}

const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Escape') closeHandler();
}

onMounted(() => {
  window.addEventListener('keyup', onKeyDown);
});

onUnmounted(() => {
  window.removeEventListener('keyup', onKeyDown);
});

watch(() => props.isOpen, (isOpen: boolean) => {
  if (isOpen) {
    emits('onOpen');
  } else {
    emits('onClose');
  }
});
</script>